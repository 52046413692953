import React, { useState, useRef, useEffect } from "react";
import zoomPlugin from "chartjs-plugin-zoom";
import { Button, Modal } from "react-bootstrap";
import { Chart } from "react-chartjs-2";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useHistory } from "react-router-dom";
import {
  getCsvFile,
  getPatientVisitDetails,
  updateVisitStatus,
} from "../../actions/patientActions";
import { getEegGraph, getEegGraphFilter } from "../../actions/graphActions";
import fastBackwardIcon from "../../assets/img/svg/fast-backward-icon.svg";
import backwardIcon from "../../assets/img/svg/backward-icon.svg";
import playIcon from "../../assets/img/svg/play-icon.svg";
import pauseIcon from "../../assets/img/svg/pause-icon.svg";
import fastForwardIcon from "../../assets/img/svg/fast-forward-icon.svg";
import forwardIcon from "../../assets/img/svg/forward-icon.svg";
import BackIcon from "../../assets/img/svg/back-icon.svg";
import { ArrowForwardIos, ArrowBackIos } from "@mui/icons-material";
import { formatName, formatDate } from "../../helper";
import HeatMap from "./HeatMap";
import LinearWithValueLabel from "../loader/LinearLoaderLablel";
import "./recording2.css";

const conditionMap = {
  1: "seizure",
  2: "concussion",
  3: "stroke",
  4: "Normal",
  5: "Altered Mental Status",
  0: "0",
};
const genderMap = {
  1: "M",
  2: "F",
};

Chart.register(zoomPlugin);

const Recording = (props) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [lffValue, setLffValue] = useState("Select");
  const [hffValue, setHffValue] = useState("Select");
  const [notchValue, setNotchValue] = useState("Select");
  const [alert, setAlert] = useState();

  const [charts, setCharts] = useState([]);
  const chartRefs = useRef([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataLength, setDataLength] = useState(null);

  const [eegData, setEegData] = useState(null);
  const [patientData, setPatientData] = useState();

  const [filteredEegData, setFilteredEegData] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingFilter, setLoadingFilter] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const intervalRef = useRef(null);
  const token = useSelector((state) => state.loginData.token);
  const [relatedIds, setRelatedIds] = useState(null);
  const { eegGraphData } = useSelector((state) => state.eegGraph);
  const { visitDetails } = useSelector((state) => state.patientVisitDetails);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [patientVisitDetails, setPatientVisitDetails] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [isFirmwareAdmin, setIsFirmwareAdmin] = useState(false);
  const [isResearchAdmin, setIsResearchAdmin] = useState(false);

  const updateGraph = (dataCount) => {
    const location = (
      visitDetails?.data?.[currentIndex]?.location || "N/A"
    ).split("-");

    const newCharts = ["CH1_Voltage", "CH2_Voltage"].map((channelName, i) => {
      const canvasRef = chartRefs.current[i];
      if (canvasRef?.chart) {
        canvasRef.chart.destroy();
        canvasRef.chart = null;
      }
      return createGraph(
        canvasRef,
        eegGraphData?.data,
        channelName,
        location[i],
        dataCount
      );
    });
    setCharts(newCharts);
  };

  const handleGraphButton = (type) => {
    let newCount;
    switch (type) {
      case "fast-backward":
        newCount = 0;
        stopPlaying();
        break;
      case "backward":
        newCount = Math.max(0, dataCount - 1);
        stopPlaying();
        break;
      case "forward":
        newCount = Math.min(dataLength - 1, dataCount + 1);
        stopPlaying();
        break;
      case "fast-forward":
        newCount = dataLength - 1;
        stopPlaying();
        break;
      case "play":
        if (!isPlaying) {
          if (newCount === dataLength - 1) {
            setDataCount(0);
          }
          startPlaying();
        }
        return;
      case "pause":
        stopPlaying();
        return;
      default:
        return;
    }

    setDataCount(newCount);
    updateGraph(newCount);
  };

  const startPlaying = () => {
    setIsPlaying(true);
    intervalRef.current = setInterval(() => {
      setDataCount((prevCount) => {
        if (prevCount < dataLength - 1) {
          const newCount = prevCount + 1;
          if (chartRefs.current) {
            chartRefs.current.forEach((canvasRef) => {
              if (canvasRef && canvasRef.chart) {
                canvasRef.chart.destroy();
              }
            });
          }
          updateGraph(newCount);
          return newCount;
        } else {
          stopPlaying();
          return -1;
        }
      });
    }, 500);
  };

  const stopPlaying = () => {
    setIsPlaying(false);
    clearInterval(intervalRef.current);
  };

  useEffect(() => {
    if (visitDetails?.data) {
      dispatch(updateVisitStatus(token, visitDetails?.data[0]?.patient_visit));
    }
  }, [dispatch, token, visitDetails?.data]);

  useEffect(() => {
    return () => clearInterval(intervalRef.current);
  }, []);

  const handleBack = () => {
    window.history.back();
  };

  const createGraph = (canvasRef, data, label, location, dataCount) => {
    if (!canvasRef) {
      console.error("Canvas reference is null or undefined");
      return;
    }
    const canvasElement = canvasRef?.getContext?.("2d")?.canvas;
    if (canvasRef.chart) {
      canvasElement?.removeEventListener(
        "dblclick",
        canvasRef.handleDoubleClick
      );
      canvasRef.chart.destroy();
      canvasRef.chart = null;
    }

    const handleDoubleClick = () => {
      canvasRef.chart.options.scales.y.min = null;
      canvasRef.chart.options.scales.y.max = null;
      canvasRef.chart.resetZoom();
      canvasRef.chart.update();
    };

    const chart = new Chart(canvasRef, {
      type: "line",
      data: {
        labels: data.length === 0 ? [] : data.length[dataCount],
        datasets: [
          {
            data:
              data.length === 0
                ? []
                : isFilter
                ? filteredEegData[label][dataCount]
                : data[label][dataCount],
            backgroundColor: "rgba(0, 0, 0, 0)",
            borderColor: "rgba(0, 0, 0, 1)",
            borderWidth: 0.4,
            pointRadius: 0,
          },
        ],
      },
      options: {
        scales: {
          x: {
            grid: {
              display: true,
            },
            display: true,
            ticks: {
              display: label === "CH2_Voltage",
              type: "linear",
              stepSize: 1,
              maxTicksLimit: 10,
              beginAtZero: true,
              align: "center",
              maxRotation: 0,
              minRotation: 0,
              padding: 0,
              font: {
                size: 13,
              },
            },
            title: {
              display: label === "CH2_Voltage",
              text: "Time (seconds)",
              padding: 0,
              font: {
                size: 14,
              },
            },
          },
          y: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
            title: {
              display: true,
              text: [location],
              font: {
                size: 14,
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          zoom: {
            pan: {
              enabled: true,
              mode: "x",
            },
            zoom: {
              wheel: {
                enabled: true,
                mode: "x",
              },
            },
          },
        },

        maintainAspectRatio: false,
        animation: false,
        spanGaps: true,
      },
    });

    if (canvasElement) {
      canvasElement.addEventListener("dblclick", handleDoubleClick);
      canvasRef.handleDoubleClick = handleDoubleClick;
    }

    canvasRef.chart = chart;
    return chart;
  };

  useEffect(() => {
    const fetchVisit = async () => {
      try {
        const visits = await dispatch(getPatientVisitDetails(token, params.id));

        if (visits?.data?.length > 0) {
          setPatientVisitDetails(visits?.data[0]);
          const relatedIds = visits?.data?.map((record) => record.id);
          setRelatedIds(relatedIds);
        } else {
          toast.error("Visit details not found");
        }
      } catch (error) {
        setLoading(false);
        setAlert(error.response);
        toast.error("Error fetching data of patient visit", {
          autoClose: 3000,
          position: "top-right",
        });
      }
    };

    fetchVisit();
  }, [dispatch, token, params.id]);

  useEffect(() => {
    const fetchEegData = async () => {
      try {
        if (relatedIds?.length > 0) {
          setLoading(true);
          await dispatch(getEegGraph(token, relatedIds[currentIndex]));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setAlert(error.response);
        toast.error("Error fetching data", {
          autoClose: 3000,
          position: "top-right",
        });
      }
    };

    fetchEegData();
  }, [dispatch, token, currentIndex, relatedIds]);

  useEffect(() => {
    if (!loading && eegGraphData) {
      setPatientData(eegGraphData?.data?.patient_data);
      const currentData = eegGraphData?.data;
      setDataLength(currentData.CH1_Voltage?.length || 0);
      setEegData(eegGraphData?.data);

      const location = (
        visitDetails?.data?.[currentIndex]?.location || "N/A"
      ).split("-");

      // Destroy old charts before creating new ones
      chartRefs.current.forEach((canvasRef) => {
        if (canvasRef?.chart) {
          canvasRef.chart.destroy();
          canvasRef.chart = null;
        }
      });

      const newCharts = ["CH1_Voltage", "CH2_Voltage"].map((channelName, i) =>
        createGraph(
          chartRefs.current[i],
          currentData,
          channelName,
          location[i],
          dataCount
        )
      );

      setCharts(newCharts);
    }
    // Cleanup on unmount
    return () => {
      chartRefs.current.forEach((canvasRef) => {
        if (canvasRef?.chart) {
          canvasRef.chart.destroy();
          canvasRef.chart = null;
        }
      });
    };
  }, [loading, eegGraphData]);

  // Apply filters
  const applyFilters = async () => {
    if ([lffValue, hffValue, notchValue].includes("Select")) {
      toast.error("Please select valid values for all filters.");
      return;
    }
    setLoadingFilter(true);
    const filterData = await dispatch(
      getEegGraphFilter(
        token,
        [relatedIds[currentIndex]],
        lffValue,
        hffValue,
        notchValue
      )
    );
    setLoadingFilter(false);

    if (filterData.length > 0) {
      setDataLength(filterData[0]?.CH1_Voltage?.length || 0);
      setIsFilter(true);
      setFilteredEegData(filterData[0]);
      setDataCount(0);
    } else {
      toast.error("Error applying filters");
    }
  };

  const resetFilter = () => {
    setDataCount(0);
    setIsFilter(false);
    setFilteredEegData(null);
    setLffValue("Select");
    setHffValue("Select");
    setNotchValue("Select");
  };

  useEffect(() => {
    if (isFilter && filteredEegData && !loadingFilter) {
      updateGraph(0);
    }
  }, [isFilter, filteredEegData, loadingFilter]);

  const downloadCSV = async () => {
    setDownloading(true);
    try {
      const fileData = await dispatch(
        getCsvFile(
          token,
          patientVisitDetails?.id,
          patientVisitDetails?.eeg_file_s3
        )
      );
      if (fileData) {
        const fileUrl = fileData?.presigned_url;
        if (fileUrl) {
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = fileData?.filename;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } else {
        toast.error("Error: Failed to retrieve file URL");
      }
    } catch (error) {
      toast.error("Failed to download the file");
    } finally {
      setDownloading(false);
    }
  };

  useEffect(() => {
    if (token) {
      const data = token.split(".")[1];
      const parsedData = JSON.parse(atob(data));
      setIsFirmwareAdmin(parsedData.data[0].is_firmware_admin);
      setIsResearchAdmin(parsedData.data[0].is_research_admin);
    }
  }, []);

  return (
    <div>
      {alert ? (
        <Modal.Dialog>
          <Modal.Header>
            <Modal.Title>Data Not Available</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>No data was found for this patient visit</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={(e) => {
                history.goBack();
              }}
            >
              Go Back
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      ) : (
        <div className="recording2">
          <div className="recording-graph-panel">
            <div className="info-container">
              <div className="personal-info">
                <div className="btn-back">
                  <span className="back-info-btn">
                    <img src={BackIcon} alt="back-icon" onClick={handleBack} />
                  </span>
                  <div className="back-info-btn" onClick={handleBack}>
                    Back
                  </div>
                </div>

                <h1>{patientData?.id_number ?? "N/A"}</h1>
                <div className="info-wrapper">
                  {/* <div className="info">
                    <p>Name:</p>
                    <p>{patientData?.name || "N/A"} </p>
                  </div> */}
                  <div className="info">
                    <p>Age / Gender: </p>
                    <p>
                      {patientData?.age || "N/A"}{" "}
                      {genderMap[patientData?.gender] || "N/A"}
                    </p>
                  </div>
                  {/* <div className="info">
                    <p>Patient#: </p>
                    <p>{patientData?.id || "N/A"}</p>
                  </div> */}
                  <div className="info">
                    <p>Recording Date: </p>
                    <p>
                      {patientData?.timestamp
                        ? formatDate(patientData?.timestamp)
                        : "N/A"}
                    </p>
                  </div>
                  <div className="info">
                    <p>Condition: </p>
                    <p>
                      {conditionMap[patientData?.clinical_condition] || "N/A"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="btns-container">
                {/* <button className="button btn-invert-rec">Add Notes</button>
                <button className="button btn-default-rec">Share Report</button> */}
                {(isResearchAdmin || isFirmwareAdmin) && (
                  <button
                    type="button"
                    className="button btn-default-rec"
                    onClick={downloadCSV}
                    disabled={downloading || loading}
                  >
                    {downloading ? "Downloading" : "Download CSV"}
                  </button>
                )}
              </div>
            </div>

            <div className="eeg-graph-panel">
              {loading || loadingFilter ? (
                <div className="linear-loader">
                  <LinearWithValueLabel increment={1} />
                </div>
              ) : (
                <>
                  <div className="eeg-header">
                    <div className="header-stats">
                      <div className="header-stat">
                        <p>
                          Display: <span>10s</span>
                        </p>
                      </div>
                      <div className="header-stat">
                        <p>
                          Scale: <span>10uv/mm</span>
                        </p>
                      </div>
                      <div className="header-stat">
                        <p>LFF: </p>
                        <div
                          className="dropdown"
                          style={{ paddingLeft: "4px" }}
                        >
                          <button
                            className="btn green-btn2 btn-sm dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            defaultValue="1Hz"
                            style={{ fontWeight: "bold", fontSize: "10" }}
                          >
                            {lffValue}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("N/A")}
                            >
                              N/A
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("1Hz")}
                            >
                              1Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("5Hz")}
                            >
                              5Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("15Hz")}
                            >
                              15Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setLffValue("20Hz")}
                            >
                              20Hz
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="header-stat">
                        <p>HFF: </p>
                        <div
                          className="dropdown"
                          style={{ paddingLeft: "4px" }}
                        >
                          <button
                            className="btn green-btn2 btn-sm dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            defaultValue="HFF4"
                            style={{ fontWeight: "bold", fontSize: "10" }}
                          >
                            {hffValue}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("N/A")}
                            >
                              N/A
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("15Hz")}
                            >
                              15 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("30Hz")}
                            >
                              30 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("40Hz")}
                            >
                              40 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={() => setHffValue("70Hz")}
                            >
                              70 Hz
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="header-stat">
                        <p>Notch: </p>
                        <div
                          className="dropdown"
                          style={{ paddingLeft: "4px" }}
                        >
                          <button
                            className="btn btn-sm green-btn2 dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            defaultValue="HFF4"
                            style={{ fontWeight: "bold", fontSize: "10" }}
                          >
                            {notchValue}
                          </button>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                          >
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={(e) => {
                                setNotchValue("N/A");
                              }}
                            >
                              N/A
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={(e) => {
                                setNotchValue("50Hz");
                              }}
                            >
                              50 Hz
                            </button>
                            <button
                              className="dropdown-item"
                              type="button"
                              onClick={(e) => {
                                setNotchValue("60Hz");
                              }}
                            >
                              60 Hz
                            </button>
                          </div>
                        </div>
                        <button
                          className="btn green-btn32 btn-sm"
                          type="button"
                          style={{
                            fontWeight: "bold",
                            fontSize: "6",
                          }}
                          onClick={() => {
                            applyFilters(lffValue, hffValue, notchValue);
                          }}
                        >
                          Apply Filters
                        </button>
                      </div>
                    </div>
                    <div className="location-btns">
                      {visitDetails?.data?.length > 1 && (
                        <button
                          className="btn loc-btn"
                          type="button"
                          onClick={() => {
                            if (currentIndex > 0) {
                              setCurrentIndex((prevIndex) =>
                                prevIndex > 0 ? prevIndex - 1 : 0
                              );
                              resetFilter();
                            }
                          }}
                          disabled={currentIndex === 0}
                        >
                          <ArrowBackIos
                            style={{ fontSize: 12, marginRight: 4 }}
                          />
                          <span className="loc-name">Back</span>
                        </button>
                      )}

                      <span className="loc-name">
                        {visitDetails?.data?.[currentIndex]?.location ?? "N/A"}
                      </span>
                      {visitDetails?.data?.length > 1 && (
                        <button
                          className="btn loc-btn"
                          type="button"
                          onClick={() => {
                            if (
                              currentIndex < relatedIds.length - 1 ||
                              currentIndex <= 4
                            ) {
                              setCurrentIndex((prevIndex) => prevIndex + 1);
                              resetFilter();
                            }
                          }}
                          disabled={
                            currentIndex === relatedIds.length - 1 ||
                            currentIndex > 4
                          }
                        >
                          <span className="loc-name">Next</span>
                          <ArrowForwardIos
                            style={{ fontSize: 12, marginLeft: 4 }}
                          />
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="graph-box">
                    {[1, 2].map((_, index) => (
                      <div key={index} className="canvas-wrapper">
                        <canvas
                          ref={(el) => (chartRefs.current[index] = el)}
                          height={index === 1 ? 120 : 100}
                        />
                      </div>
                    ))}
                  </div>

                  <div className="eeg-graph-controls">
                    <button
                      disabled={dataCount === 0}
                      className="control-button"
                      onClick={() => handleGraphButton("fast-backward")}
                    >
                      <img src={fastBackwardIcon} alt="fast backward" />
                    </button>

                    <button
                      disabled={dataCount === 0}
                      className="control-button"
                      onClick={() => handleGraphButton("backward")}
                    >
                      <img src={backwardIcon} alt="backward" />
                    </button>

                    <button
                      className="control-button"
                      onClick={() =>
                        handleGraphButton(isPlaying ? "pause" : "play")
                      }
                    >
                      <img
                        src={isPlaying ? pauseIcon : playIcon}
                        alt={isPlaying ? "pause" : "play"}
                      />
                    </button>

                    <button
                      disabled={dataCount === dataLength - 1}
                      className="control-button"
                      onClick={() => handleGraphButton("forward")}
                    >
                      <img src={forwardIcon} alt="forward" />
                    </button>

                    <button
                      disabled={dataCount === dataLength - 1}
                      className="control-button"
                      onClick={() => handleGraphButton("fast-forward")}
                    >
                      <img src={fastForwardIcon} alt="fast forward" />
                    </button>
                  </div>
                </>
              )}
            </div>
            <div
              className={
                loading || loadingFilter ? "pwelch-hide" : "pwelch-show"
              }
            >
              <HeatMap
                timeLength={
                  eegData
                    ? eegData[0]?.length[
                        dataCount === -1
                          ? eegData[0]?.length?.length - 1
                          : dataCount
                      ]
                    : []
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Recording;
