import React, { useState, useRef, useEffect } from "react";
import notificationsIcon from "../../assets/img/svg/notifications.svg";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./notifications.css";
import {
  getNotifications,
  readNotification,
  markAllReadNotifications,
} from "../../actions/doctorActions";

const Notifications = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const dropdownRef = useRef(null);
  const token = useSelector((state) => state.loginData.token);
  const notificationsList = useSelector((state) => state.notifications);
  const { notifications } = notificationsList;

  useEffect(() => {
    dispatch(getNotifications(token));
  }, [dispatch, token]);

  const handleNotificationClick = (notification) => {
    if (notification.status === "unread") {
      dispatch(readNotification(notification.id, token));
      dispatch(getNotifications(token));
    }
    history.push(notification.action_url);
  };

  const handleMarkAllRead = () => {
    dispatch(markAllReadNotifications(token));
    dispatch(getNotifications(token));
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const date = new Date(timestamp);
    const diff = now - date;
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    return "Just now";
  };

  return (
    <div className="notifications-container" ref={dropdownRef}>
      <div
        className="notifications-icon-button border-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <img
          src={notificationsIcon}
          alt="notifications"
          className="notifications-icon"
        />
        {notifications?.unread_count > 0 && (
          <span className="notifications-badge" />
        )}
      </div>

      {isOpen && (
        <div className="notifications-dropdown">
          <div className="notifications-header">
            <h3>
              Notifications{" "}
              {notifications?.unread_count > 0 ? (
                <>({notifications?.unread_count})</>
              ) : (
                ""
              )}
            </h3>
          </div>

          <div className="notifications-content">
            {notifications?.notifications?.length === 0 ? (
              <p className="notifications-empty">No notifications</p>
            ) : (
              <div className="notifications-list">
                {notifications?.notifications?.map((notification) => (
                  <div
                    key={notification.id}
                    className={`notification-item ${
                      notification.status === "unread" ? "unseen" : ""
                    }`}
                    onClick={() => handleNotificationClick(notification)}
                  >
                    <p className="notification-message">
                      <b> {notification.subject}</b>
                    </p>
                    <p className="notification-message">
                      {notification.message}
                    </p>
                    <p className="notification-time">
                      {formatTimestamp(notification.created_at)}
                    </p>
                  </div>
                ))}
              </div>
            )}
          </div>

          {notifications?.notifications?.length > 0 && (
            <div className="notifications-footer" onClick={handleMarkAllRead}>
              <button className="mark-read-button">Mark all as read</button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Notifications;
