import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getConcussionForm } from "../../actions/patientActions";
import Loader from "../loader/Loader";
import { formatDateTime } from "../../helper";

const CHOICES = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];

const GENDER_MAP = {
  M: "Male",
  F: "Female",
};

const VISIT_TYPES = {
  followup: "Follow-Up Visit",
  first_visit: "First Visit",
};

const HAND_MAP = {
  R: "Right",
  L: "Left",
  A: "Ambidextrous",
};

const PERFORMANCE_MAP = {
  ABV: "Above Average",
  AVG: "Average",
  BLW: "Below Average",
};

export default function ConcussionFollowUpFormDetail() {
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginData.token);
  const { loading, concussionForm } = useSelector(
    (state) => state.concussionForm
  );

  useEffect(() => {
    dispatch(getConcussionForm(token, params.id)).catch((error) => {
      toast.error("Error fetching concussion form");
      history.push("/concussion-forms");
    });
  }, [dispatch, token, params.id]);

  const handleBack = () => {
    window.history.back();
  };

  const renderInfoRow = (label, value, formatter = (val) => val) => (
    <div className="form-detail-row">
      <span className="form-detail-label">{label}</span>
      <span className="form-detail-value">
        {value ? formatter(value) : "N/A"}
      </span>
    </div>
  );

  const renderSymptomScore = (label, score) => (
    <div className="form-detail-row">
      <span className="form-detail-label">{label}</span>
      <span className="form-detail-value">{score}</span>
    </div>
  );

  const mapYesNo = (value) => {
    const choice = CHOICES.find((choice) => choice.value === value);
    return choice ? choice.label : "N/A";
  };

  if (loading) return <Loader />;
  if (!concussionForm) return <div>No form data available</div>;

  return (
    <div className="concussion-form-detail">
      <div className="d-header">
        <ArrowBackIcon
          fontSize="large"
          onClick={handleBack}
          className="back-detail"
        />
        <div>
          <h3>Concussion Follow-Up Form</h3>
          <p className="text-center mb-5">
            {formatDateTime(concussionForm.created_at)}
          </p>
        </div>
      </div>
      <div className="form-details-container">
        {/* Patient Information Section */}
        <div className="details-section">
          <h4>Patient Information</h4>
          {renderInfoRow("ID Number", concussionForm.id_number)}
          {renderInfoRow("Age", concussionForm.age)}
          {renderInfoRow(
            "Gender",
            concussionForm.gender,
            (val) => GENDER_MAP[val] || "N/A"
          )}
          {renderInfoRow("Date of Injury", concussionForm.date_of_injury)}
          {renderInfoRow(
            "Dominant Hand",
            concussionForm.dominant_hand,
            (val) => HAND_MAP[val] || "N/A"
          )}
          {renderInfoRow("Date of Examination", concussionForm.date_of_test)}
          {renderInfoRow("Subject Group", concussionForm.subject_group)}
          {renderInfoRow(
            "Current Year in School",
            concussionForm.current_year_in_school
          )}
          {renderInfoRow(
            "Years of Education Completed",
            concussionForm.years_education_completed
          )}
        </div>

        {/* Social History Section */}
        <div className="details-section">
          <h4>Social History</h4>
          {renderInfoRow(
            "Sleep Hours Per Night Since Injury",
            concussionForm.hours_per_night_since_injury
          )}
          {renderInfoRow(
            "Screen Time Per Day",
            concussionForm.screen_time_hours_per_day
          )}
          {renderInfoRow(
            "Eating Habits Changed",
            concussionForm.eating_habits_changed,
            mapYesNo
          )}
          {renderInfoRow(
            "Eating Habits Explanation",
            concussionForm.eating_habits_explanation
          )}
        </div>

        {/* Medical History Section */}
        <div className="details-section">
          <h4>Medical History</h4>
          <div className="form-detail-row">
            <span className="form-detail-label">Medications</span>
            <span className="form-detail-value">
              {concussionForm.medications &&
              concussionForm.medications.length > 0
                ? concussionForm.medications
                    .map((med) => `${med.name} (${med.dose})`)
                    .join(", ")
                : "None"}
            </span>
          </div>
        </div>

        {/* Symptoms Assessment Section */}
        <div className="details-section full-width">
          <h4>Current Injury - Symptoms Score</h4>
          <div className="symptoms-grid">
            {renderSymptomScore("Headache", concussionForm.headache)}
            {renderSymptomScore("Head Pressure", concussionForm.head_pressure)}
            {renderSymptomScore("Neck Pain", concussionForm.neck_pain_score)}
            {renderSymptomScore("Nausea", concussionForm.nausea)}
            {renderSymptomScore("Dizziness", concussionForm.dizziness)}
            {renderSymptomScore(
              "Vision Problems",
              concussionForm.vision_problems
            )}
            {renderSymptomScore(
              "Balance Problems",
              concussionForm.balance_problems
            )}
            {renderSymptomScore(
              "Light Sensitivity",
              concussionForm.sensitivity_to_light
            )}
            {renderSymptomScore(
              "Noise Sensitivity",
              concussionForm.sensitivity_to_noise
            )}
            {renderSymptomScore(
              "Feeling Slowed Down",
              concussionForm.feeling_slowed_down
            )}
            {renderSymptomScore(
              "Feeling in Fog",
              concussionForm.feeling_in_fog
            )}
            {renderSymptomScore(
              "Not Feeling Right",
              concussionForm.dont_feel_right
            )}
            {renderSymptomScore(
              "Memory Problems",
              concussionForm.memory_problems
            )}
            {renderSymptomScore(
              "Concentration Problems",
              concussionForm.concentration_problems
            )}
            {renderSymptomScore("Fatigue", concussionForm.fatigue)}
            {renderSymptomScore("Confusion", concussionForm.confusion)}
            {renderSymptomScore("Drowsiness", concussionForm.drowsiness)}
            {renderSymptomScore("More Emotional", concussionForm.anxiety)}
            {renderSymptomScore("Irritability", concussionForm.irritability)}
            {renderSymptomScore("Sadness", concussionForm.sadness)}
            {renderSymptomScore(
              "Nervousness or Anxious",
              concussionForm.nervousness
            )}
            {renderSymptomScore(
              "Trouble Falling Asleep",
              concussionForm.trouble_falling_asleep
            )}
          </div>
          <div className="border-subject-group">
            {renderInfoRow(
              "Has subject returned to school/work fulltime?",
              concussionForm.subject_returned_school,
              mapYesNo
            )}
            {renderInfoRow(
              "Notes",
              concussionForm.subject_returned_school_notes
            )}

            {renderInfoRow(
              "Is subject participating in light physical activity?",
              concussionForm.subject_physical_activity,
              mapYesNo
            )}
            {renderInfoRow(
              "Notes",
              concussionForm.subject_physical_activity_notes
            )}

            {renderInfoRow(
              "Is subject undergoing vestibular therapy?",
              concussionForm.subject_vestibular_therapy,
              mapYesNo
            )}
            {renderInfoRow(
              "Notes",
              concussionForm.subject_vestibular_therapy_notes
            )}
          </div>

          <div className="symptom-summary">
            {renderInfoRow("Total Symptoms", concussionForm.total_symptoms)}
            {renderInfoRow(
              "Symptom Severity Score",
              concussionForm.symptom_severity_score
            )}
            {renderInfoRow(
              "Worse with Physical Activity",
              concussionForm.worse_with_physical_activity,
              mapYesNo
            )}
            {renderInfoRow(
              "Worse with Mental Activity",
              concussionForm.worse_with_mental_activity,
              mapYesNo
            )}
            {renderInfoRow(
              "Percent Feeling Normal",
              `${concussionForm.percent_feeling_normal}%`
            )}
            {renderInfoRow(
              "Symptoms Explanation",
              concussionForm.symptoms_explanation
            )}

            {renderInfoRow(
              "Subject cleared from concussion at this visit",
              concussionForm.subject_cleared_in_visit ? "Yes" : "No"
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
