import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  getConcussionForm,
  updateConcussionForm,
} from "../../../actions/patientActions";

const validationSchema = Yup.object().shape({
  id_number: Yup.string().required("Please enter a valid ID Number"),
  years_education_completed: Yup.number()
    .nullable()
    .typeError("Years of Education Completed must be in numbers"),
});

const ConcussionFollowUpForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const concussionFormData = useSelector((state) => state.concussionForm);
  const token = useSelector((state) => state.loginData.token);
  const { concussionForm = [] } = concussionFormData;

  const fixedValues = {
    patient: concussionForm?.patient,
    id_number: concussionForm?.id_number,
    age: concussionForm?.age,
    gender: concussionForm?.gender,
    date_of_injury: concussionForm?.date_of_injury,
    dominant_hand: concussionForm?.dominant_hand,
    subject_group: concussionForm?.subject_group,
    current_year_in_school: concussionForm?.current_year_in_school,
    years_education_completed: concussionForm?.years_education_completed,
  };

  const defaultValues = {
    date_of_test: null,
  };
  const storedForm = JSON.parse(
    localStorage.getItem(`ConcussionFollowUp1-${id}`)
  );
  const initialValues = { ...defaultValues, ...storedForm, ...fixedValues };

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error! " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id]);

  const handleSubmit = (values, { setSubmitting }) => {
    localStorage.setItem(`ConcussionFollowUp1-${id}`, JSON.stringify(values));
    history.push(`/concussion-forms/followup/2/${id}`);
    setSubmitting(false);
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Concussion Follow-up Form</span>
        <span className="form-progress">1/7</span>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper" style={{ position: "relative" }}>
                <label className="field-label">ID Number</label>
                <Field
                  name="id_number"
                  className="field-input"
                  placeholder="Search by ID Number"
                  value={fixedValues?.id_number}
                  disabled
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Age</label>
                <Field
                  name="age"
                  className="field-input"
                  value={fixedValues?.age}
                  disabled
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Gender</label>
                <Field name="gender">
                  {({ field }) => (
                    <input
                      {...field}
                      className="field-input"
                      disabled
                      value={
                        field.value === "M"
                          ? "Male"
                          : field.value === "F"
                          ? "Female"
                          : ""
                      }
                    />
                  )}
                </Field>
              </div>

              <div className="field-wrapper">
                <label className="field-label">Date of Injury</label>
                <Field name="date_of_injury" className="field-input" disabled />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Dominant Hand</label>
                <Field name="dominant_hand">
                  {({ field }) => (
                    <input
                      {...field}
                      className="field-input"
                      disabled
                      value={
                        field.value === "R"
                          ? "Right"
                          : field.value === "L"
                          ? "Left"
                          : field.value === "A"
                          ? "Ambidextrous"
                          : ""
                      }
                    />
                  )}
                </Field>
              </div>
              <div className="field-wrapper">
                <label className="field-label">Date of Examination</label>
                <Field
                  name="date_of_test"
                  type="date"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="date_of_test"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Subject Group</label>
                <div role="group" className="tabs-group">
                  {["Cohort", "Control"].map((option) => (
                    <Field name="subject_group" key={option}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("subject_group", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="subject_group"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Current Year in School</label>
                <Field name="current_year_in_school" className="field-input" />
                <ErrorMessage
                  name="current_year_in_school"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">
                  Years of Education Completed
                </label>
                <Field
                  name="years_education_completed"
                  className="field-input"
                />
                <ErrorMessage
                  name="years_education_completed"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btns-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() => history.push("/concussion-forms")}
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
              {/* {id && (
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                  onClick={() => setSave(true)}
                >
                  SAVE
                </button>
              )} */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ConcussionFollowUpForm;
