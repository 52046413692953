export const DOCTOR_LIST_REQUEST = "DOCTOR_LIST_REQUEST";
export const DOCTOR_LIST_SUCCESS = "DOCTOR_LIST_SUCCESS";
export const DOCTOR_LIST_FAIL = "DOCTOR_LIST_FAIL";

export const PATIENT_VISITS_DETAIL_REQUEST = "PATIENT_VISITS_DETAIL_REQUEST";
export const PATIENT_VISITS_DETAIL_SUCCESS = "PATIENT_VISITS_DETAIL_SUCCESS";
export const PATIENT_VISITS_DETAIL_FAIL = "PATIENT_VISITS_DETAIL_FAIL";

export const HOSPITAL_LIST_REQUEST = "HOSPITAL_LIST_REQUEST";
export const HOSPITAL_LIST_FAIL = "HOSPITAL_LIST_FAIL";
export const HOSPITAL_LIST_SUCCESS = "HOSPITAL_LIST_SUCCESS";

export const NOTIFICATIONS_REQUEST = "NOTIFICATIONS_REQUEST";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";
export const NOTIFICATIONS_FAIL = "NOTIFICATIONS_FAIL";

export const READ_NOTIFICATION_REQUEST = "READ_NOTIFICATION_REQUEST";
export const READ_NOTIFICATION_SUCCESS = "READ_NOTIFICATION_SUCCESS";
export const READ_NOTIFICATION_FAIL = "READ_NOTIFICATION_FAIL";

export const MARK_ALL_NOTIFICATIONS_READ_REQUEST =
  "MARK_ALL_NOTIFICATIONS_READ_REQUEST";
export const MARK_ALL_NOTIFICATIONS_READ_SUCCESS =
  "MARK_ALL_NOTIFICATIONS_READ_SUCCESS";
export const MARK_ALL_NOTIFICATIONS_READ_FAIL =
  "MARK_ALL_NOTIFICATIONS_READ_FAIL";
