import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getConcussionForm,
  updateConcussionForm,
} from "../../../actions/patientActions";
import { toast } from "react-toastify";
import { updateScore } from "../../../helper";

const CurrentInjury5 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [save, setSave] = useState(false);
  const { id } = useParams();
  const token = useSelector((state) => state.loginData.token);
  const concussionFormData = useSelector((state) => state.concussionForm);
  const { concussionForm = {} } = concussionFormData;

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error fetching data: " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id, history]);

  const defaultValues = {
    anxiety: 0,
    irritability: 0,
    sadness: 0,
    nervousness: 0,
    trouble_falling_asleep: 0,
  };

  const storedForm = JSON.parse(
    localStorage.getItem(`ConcussionFollowUp5-${id}`)
  );
  const initialValues = { ...defaultValues, ...storedForm };

  const handleSubmit = (values, { setSubmitting }) => {
    localStorage.setItem(`ConcussionFollowUp5-${id}`, JSON.stringify(values));
    history.push(`/concussion-forms/followup/6/${id}`);
  };
  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Current Injury</span>
        <span className="form-progress">5/7</span>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">More emotional</label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="anxiety">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() => form.setFieldValue("anxiety", option)}
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="anxiety"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Irritability</label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="irritability">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("irritability", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="irritability"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Sadness </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="sadness">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() => form.setFieldValue("sadness", option)}
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="sadness"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Nervous or anxious</label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="nervousness">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("nervousness", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="nervousness"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Trouble falling asleep</label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="trouble_falling_asleep">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("trouble_falling_asleep", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="trouble_falling_asleep"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btns-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() =>
                  history.push(`/concussion-forms/followup/4/${id}`)
                }
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
              {/* {id && (
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                  onClick={() => setSave(true)}
                >
                  SAVE
                </button>
              )} */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CurrentInjury5;
