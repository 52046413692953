import "./App.css";
import PatientDashboard from "./components/patient-dashboard/PatientDashboard";
import UpdatedLogin from "./components/UpdatedLogin";
import VerifyAccount from "./components/VerifyAccount";
import ResetPassword from "./components/ResetPassword";
import ResetPasswordConfirm from "./components/ResetPasswordConfirm";
import AdminPanelHeader from "./components/AdminPanelHeader";
import Header from "./components/header/Header";
import Dashboard from "./components/Dashboard";
import NewPatient from "./screens/NewPatient";
import PatientVisitDetails from "./components/patient-visits/PatientVisitDetails";
import HomePage from "./screens/HomePage";
import PenDetail from "./screens/PenDetail";
import RecordingAll from "./components/RecordingAll";
import Epochs from "./components/epochs";
import EpochsVisuals from "./components/EpochsVisuals";
import PaymentPlan from "./components/PaymentPlan";
import PaymentCard from "./components/Card";
import Recording from "./components/recording2Updated/Recording";
import FileUpload from "./components/FileUpload";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import PatientListUpdated from "./components/patient-list-updated/PatientListUpdated";
import DeletedPatientList from "./components/deleted-patient-list/DeletedPatientList";
import DeletedVisitList from "./components/deleted-visit-list/DeletedVisitList";
import SubjectListUpdated from "./components/subject-list-updated/SubjectListUpdated";
import RecordingListUpdated from "./components/recordings-list-updated/RecordingListUpdated";
import StudiesListUpdated from "./components/studies-list-updated/StudiesListUpdated";
import AccountSetting from "./components/AccountSetting";
import Register from "./components/Register-updated/Register";
import { useEffect } from "react";
import EditPatient from "./components/Tabs/EditPatient";
import FirmwareFormComponent from "./components/firmware/FirmwareFormComponent";
import FirmwareUpdate from "./components/firmware-update/FirmwareUpdate";
import FirmwareList from "./components/firmware-list/FirmwareList";
import CompareVisits from "./components/compare-visits/CompareVisits";
import ConcussionFormsTable from "./components/concussion-forms/ConcussionFormsTable";
import ConcussionIntakeForm from "./components/concussion-forms/forms/ConcussionIntakeForm";
import ConcussionHistory from "./components/concussion-forms/forms/ConcussionHistory";
import SocialHistory from "./components/concussion-forms/forms/SocialHistory";
import SocialHistory2 from "./components/concussion-forms/forms/SocailHistory2";
import MedicalHistory from "./components/concussion-forms/forms/MedicalHistory";
import MedicalHistory2 from "./components/concussion-forms/forms/MedicalHistory2";
import CurrentInjury from "./components/concussion-forms/forms/current-injury-forms/CurrentInjury";
import CurrentInjury2 from "./components/concussion-forms/forms/current-injury-forms/CurrentInjury2";
import CurrentInjury3 from "./components/concussion-forms/forms/current-injury-forms/CurrentInjury3";
import CurrentInjury4 from "./components/concussion-forms/forms/current-injury-forms/CurrentInjury4";
import CurrentInjury5 from "./components/concussion-forms/forms/current-injury-forms/CurrentInjury5";
import CurrentInjury6 from "./components/concussion-forms/forms/current-injury-forms/CurrentInjury6";
import SwayForm from "./components/sway-forms/SwayForm";
import PatientVisitsDetail from "./components/recording-list-v1/PatientVisitsDetail";
import SwayForm2 from "./components/sway-forms/SwayForm2";
import EditPatientInformation from "./components/patients/EditPatientInformation";
import ConcussionFormDetail from "./components/concussion-forms/ConcussionFormDetail";
import SwayFormsTable from "./components/sway-forms/SwayFormsTable";
import SwayFormDetail from "./components/sway-forms/SwayFormDetail";
import PatientDashboardResearch from "./components/patient-dashboard/PatientDashboardResearch";
import ConcussionFollowUpForm from "./components/concussion-forms/concussion-followup-forms/ConcussionFollowUpForm";
import ConcussionFollowUpForm2 from "./components/concussion-forms/concussion-followup-forms/SocialHistory";
import ConcussionFollowUpForm3 from "./components/concussion-forms/concussion-followup-forms/CurrentInjury3";
import ConcussionFollowUpForm4 from "./components/concussion-forms/concussion-followup-forms/CurrentInjury4";
import ConcussionFollowUpForm5 from "./components/concussion-forms/concussion-followup-forms/CurrentInjury5";
import ConcussionFollowUpForm6 from "./components/concussion-forms/concussion-followup-forms/CurrentInjury6";
import ConcussionFollowUpForm7 from "./components/concussion-forms/concussion-followup-forms/CurrentInjury7";
import ConcussionFollowUpFormDetail from "./components/concussion-forms/ConcussionFollowUpFormDetail";

function App() {
  const stateValue = useSelector((state) => state.loginData.status);
  useEffect(() => {
    // Check if token is available in localStorage
    if (localStorage.token) {
      console.log("Token is available in localStorage");
      let data = localStorage.token.split(".")[1];
      const parsedData = JSON.parse(atob(data));
      const currentDate = new Date();
      const expired =
        Math.floor(currentDate.getTime() / 1000) >= parsedData.exp;

      // Check if token is expired
      if (expired) {
        localStorage.clear();
        window.location.reload();
      }
    }
  }, []);

  const ProtectedRoutes = () => {
    if (stateValue === false) {
      return (
        <Switch>
          {/* <Route path="/home" exact>
            <Home></Home>
          </Route> */}
          <Route path="/" exact>
            <HomePage />
          </Route>
          <Route path="/technology" exact>
            <PenDetail />
          </Route>
          <Route path="/login" exact>
            <UpdatedLogin></UpdatedLogin>
          </Route>
          <Route path="/verify_account/:token" exact>
            <VerifyAccount></VerifyAccount>
          </Route>
          <Route path="/reset-password" exact>
            <ResetPassword></ResetPassword>
          </Route>
          <Route path="/password-reset/confirm/:uid/:token" exact>
            <ResetPasswordConfirm></ResetPasswordConfirm>
          </Route>
          <Route path="/register" exact>
            <Register />
          </Route>
          <Route path="/payment" exact>
            <PaymentPlan />
          </Route>
          <Route path="/card" exact>
            <PaymentCard />
          </Route>
          dashboard
          <Redirect from="*" to="/" />
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path="/" exact>
            <HomePage />
          </Route>

          <Route path="/technology" exact>
            <PenDetail />
          </Route>

          <Route path="/all-recordings" exact>
            <RecordingAll />
          </Route>

          <Route path="/epochs" exact>
            <Epochs />
          </Route>

          {/* <Route path="/account-settings" exact>
            <AccountSetting />
          </Route> */}

          <Route path="/epochs-display/:key" exact component={EpochsVisuals} />

          <Route path="/dashboard" exact>
            <PatientDashboard />
          </Route>

          <Route path="/dashboard-research" exact>
            <PatientDashboardResearch />
          </Route>

          <Route path="/firmware" exact>
            <Header></Header>
            <FirmwareList></FirmwareList>
          </Route>

          <Route path="/firmware-create" exact>
            <Header></Header>
            <FirmwareFormComponent></FirmwareFormComponent>
          </Route>

          <Route path="/firmware-update/:id" exact>
            <Header></Header>
            <FirmwareUpdate></FirmwareUpdate>
          </Route>

          <Route path="/deleted-patient-list" exact>
            <Header></Header>
            <DeletedPatientList></DeletedPatientList>
          </Route>

          <Route path="/deleted-visit-list" exact>
            <Header></Header>
            <DeletedVisitList></DeletedVisitList>
          </Route>

          {/* old edit patient information  */}
          {/* <Route path="/edit-patient/:id" exact>
            <Header></Header>
            <EditPatient></EditPatient>
          </Route> */}

          {/* new patient information  */}
          <Route path="/edit-patient/:id" exact>
            <Header />
            <EditPatientInformation />
          </Route>

          <Route path="/patient-cards" exact>
            <Header></Header>
            <Dashboard></Dashboard>
          </Route>

          <Route path="/payment" exact>
            <PaymentPlan />
          </Route>

          <Route path="/patients" exact>
            <AdminPanelHeader></AdminPanelHeader>
            <NewPatient />
          </Route>

          <Route path="/listpatients" exact>
            <Header></Header>
            <PatientListUpdated></PatientListUpdated>
          </Route>

          <Route path="/listsubjects" exact>
            <Header></Header>
            <SubjectListUpdated></SubjectListUpdated>
          </Route>

          <Route path="/listrecordings_v0/:id" exact>
            <Header></Header>
            <RecordingListUpdated></RecordingListUpdated>
          </Route>

          {/* <Route path="/listrecordings/:id" exact>
            <Header />
            <RecordingListV1 />
          </Route> */}

          <Route path="/listrecordings/:id" exact>
            <Header />
            <PatientVisitsDetail />
          </Route>

          <Route path="/compare-visits" exact>
            <Header />
            <CompareVisits />
          </Route>

          <Route path="/liststudies" exact>
            <Header></Header>
            <StudiesListUpdated></StudiesListUpdated>
          </Route>

          <Route path="/sway-forms" exact>
            <Header />
            <SwayFormsTable />
          </Route>

          <Route path="/sway-form/:id" exact>
            <Header />
            <SwayFormDetail />
          </Route>

          <Route path="/sway-form" exact>
            <Header />
            <SwayForm />
          </Route>

          <Route path="/sway-forms/1/:id?" exact>
            <Header />
            <SwayForm />
          </Route>

          <Route path="/sway-forms/2/:id?" exact>
            <Header />
            <SwayForm2 />
          </Route>

          <Route path="/concussion-form/followup/:id" exact>
            <Header />
            <ConcussionFollowUpFormDetail />
          </Route>

          <Route path="/concussion-forms/followup/1/:id" exact>
            <Header />
            <ConcussionFollowUpForm />
          </Route>

          <Route path="/concussion-forms/followup/2/:id" exact>
            <Header />
            <ConcussionFollowUpForm2 />
          </Route>

          <Route path="/concussion-forms/followup/3/:id" exact>
            <Header />
            <ConcussionFollowUpForm3 />
          </Route>

          <Route path="/concussion-forms/followup/4/:id" exact>
            <Header />
            <ConcussionFollowUpForm4 />
          </Route>

          <Route path="/concussion-forms/followup/5/:id" exact>
            <Header />
            <ConcussionFollowUpForm5 />
          </Route>

          <Route path="/concussion-forms/followup/6/:id" exact>
            <Header />
            <ConcussionFollowUpForm6 />
          </Route>

          <Route path="/concussion-forms/followup/7/:id" exact>
            <Header />
            <ConcussionFollowUpForm7 />
          </Route>

          <Route path="/concussion-forms" exact>
            <Header />
            <ConcussionFormsTable />
          </Route>
          <Route path="/concussion-form/:id" exact>
            <Header />
            <ConcussionFormDetail />
          </Route>
          <Route path="/concussion-forms/1/:id?" exact>
            <Header />
            <ConcussionIntakeForm />
          </Route>

          <Route path="/concussion-forms/2/:id?" exact>
            <Header />
            <ConcussionHistory />
          </Route>

          <Route path="/concussion-forms/3/:id?" exact>
            <Header />
            <SocialHistory />
          </Route>

          <Route path="/concussion-forms/4/:id?" exact>
            <Header />
            <SocialHistory2 />
          </Route>

          <Route path="/concussion-forms/5/:id?" exact>
            <Header />
            <MedicalHistory />
          </Route>

          <Route path="/concussion-forms/6/:id?" exact>
            <Header />
            <MedicalHistory2 />
          </Route>

          <Route path="/concussion-forms/7/:id?" exact>
            <Header />
            <CurrentInjury />
          </Route>

          <Route path="/concussion-forms/8/:id?" exact>
            <Header />
            <CurrentInjury2 />
          </Route>

          <Route path="/concussion-forms/9/:id?" exact>
            <Header />
            <CurrentInjury3 />
          </Route>

          <Route path="/concussion-forms/10/:id?" exact>
            <Header />
            <CurrentInjury4 />
          </Route>

          <Route path="/concussion-forms/11/:id?" exact>
            <Header />
            <CurrentInjury5 />
          </Route>

          <Route path="/concussion-forms/12/:id?" exact>
            <Header />
            <CurrentInjury6 />
          </Route>

          <Route path="/recording/:id" exact>
            <Header />
            <Recording />
          </Route>

          <Route path="/visits/:id" exact component={PatientVisitDetails} />
          <Route path="/fileupload/:id" exact component={FileUpload} />

          <Redirect from="*" to="/dashboard" />
        </Switch>
      );
    }
  };

  return (
    <div className="App">
      <Router>
        <ProtectedRoutes />
      </Router>
    </div>
  );
}

export default App;
