import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  getConcussionForm,
  updateConcussionForm,
} from "../../../actions/patientActions";
import { toast } from "react-toastify";

const CHOICES = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];

const validationSchema = Yup.object().shape({});

const CurrentInjury6 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [save, setSave] = useState(false);
  const { id } = useParams();
  const token = useSelector((state) => state.loginData.token);
  const concussionFormData = useSelector((state) => state.concussionForm);
  const { concussionForm = {} } = concussionFormData;

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error fetching data: " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id, history]);

  const defaultValues = {
    subject_returned_school: "N",
    subject_returned_school_notes: "",
    subject_physical_activity: "N",
    subject_physical_activity_notes: "",
    subject_vestibular_therapy: "N",
    subject_vestibular_therapy_notes: "",
  };

  const storedForm = JSON.parse(
    localStorage.getItem(`ConcussionFollowUp6-${id}`)
  );
  const initialValues = { ...defaultValues, ...storedForm };

  const handleSubmit = (values, { setSubmitting }) => {
    localStorage.setItem(`ConcussionFollowUp6-${id}`, JSON.stringify(values));
    history.push(`/concussion-forms/followup/7/${id}`);
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Current Injury</span>
        <span className="form-progress">6/7</span>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">
                  Has subject returned to school/work fulltime?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="subject_returned_school" key={option.value}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "subject_returned_school",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="subject_returned_school"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">Notes</label>
                <Field
                  name="subject_returned_school_notes"
                  className="field-input notes"
                  as="textarea"
                />
                <ErrorMessage
                  name="subject_returned_school_notes"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Is subject participating in light physical activity?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="subject_physical_activity" key={option.value}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "subject_physical_activity",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="subject_physical_activity"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">Notes</label>
                <Field
                  name="subject_physical_activity_notes"
                  className="field-input notes"
                  as="textarea"
                />
                <ErrorMessage
                  name="subject_physical_activity_notes"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Is subject undergoing vestibular therapy?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="subject_vestibular_therapy" key={option.value}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "subject_vestibular_therapy",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="subject_vestibular_therapy"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">Notes</label>
                <Field
                  name="subject_vestibular_therapy_notes"
                  className="field-input notes"
                  as="textarea"
                />
                <ErrorMessage
                  name="subject_vestibular_therapy_notes"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btns-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() =>
                  history.push(`/concussion-forms/followup/5/${id}`)
                }
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
              {/* {id && (
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                  onClick={() => setSave(true)}
                >
                  SAVE
                </button>
              )} */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CurrentInjury6;
