import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getConcussionForm,
  updateConcussionForm,
} from "../../../actions/patientActions";
import { toast } from "react-toastify";
import { updateScore } from "../../../helper";

const CurrentInjury4 = () => {
  const history = useHistory();
  const [save, setSave] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const token = useSelector((state) => state.loginData.token);
  const concussionFormData = useSelector((state) => state.concussionForm);
  const { concussionForm = {} } = concussionFormData;

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error fetching data: " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id, history]);

  const defaultValues = {
    sensitivity_to_noise: 0,
    feeling_slowed_down: 0,
    feeling_in_fog: 0,
    dont_feel_right: 0,
    memory_problems: 0,
    concentration_problems: 0,
    fatigue: 0,
    confusion: 0,
    drowsiness: 0,
  };

  const storedForm = JSON.parse(
    localStorage.getItem(`ConcussionFollowUp4-${id}`)
  );
  const initialValues = { ...defaultValues, ...storedForm };

  const handleSubmit = (values, { setSubmitting }) => {
    localStorage.setItem(`ConcussionFollowUp4-${id}`, JSON.stringify(values));
    history.push(`/concussion-forms/followup/5/${id}`);
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Current Injury</span>
        <span className="form-progress">4/7</span>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">Sensitivity to noise </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="sensitivity_to_noise">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("sensitivity_to_noise", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="sensitivity_to_noise"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Feeling slowed down</label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="feeling_slowed_down">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("feeling_slowed_down", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="feeling_slowed_down"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Feeling like "in a fog" </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="feeling_in_fog">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("feeling_in_fog", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="feeling_in_fog"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">"Don't feel right" </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="dont_feel_right">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("dont_feel_right", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="dont_feel_right"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Difficulty concentrating </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="concentration_problems">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("concentration_problems", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="concentration_problems"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Difficulty remembering </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="memory_problems">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("memory_problems", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="memory_problems"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Fatigue or low energy </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="fatigue">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() => form.setFieldValue("fatigue", option)}
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="fatigue"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Confusion </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="confusion">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("confusion", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="confusion"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">Drowsiness </label>
                <div role="group" className="tabs-group">
                  {[0, 1, 2, 3, 4, 5, 6].map((option) => (
                    <Field name="drowsiness">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("drowsiness", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="drowsiness"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btns-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() =>
                  history.push(`/concussion-forms/followup/3/${id}`)
                }
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
              {/* {id && (
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                  onClick={() => setSave(true)}
                >
                  SAVE
                </button>
              )} */}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CurrentInjury4;
