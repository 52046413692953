import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router-dom";
import {
  createConcussionForm,
  updateConcussionForm,
  getConcussionForm,
} from "../../../actions/patientActions";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { calculateScoreFollowUpForm } from "../../../helper";

const CHOICES = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];
const CurrentInjury7 = () => {
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.loginData.token);
  const concussionFormData = useSelector((state) => state.concussionForm);
  const { concussionForm = {} } = concussionFormData;
  const { totalSymptoms, severityScore } = calculateScoreFollowUpForm(id);

  const initialValues = {
    total_symptoms: totalSymptoms,
    symptom_severity_score: severityScore,
    worse_with_physical_activity: "N",
    worse_with_mental_activity: "N",
    percent_feeling_normal: 0,
    symptoms_explanation: "",
    form_category: "followup",
  };

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error fetching data: " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id, history]);

  const handleSubmit = (values, { setSubmitting }) => {
    const expectedForms = [
      `ConcussionFollowUp1-${id}`,
      `ConcussionFollowUp2-${id}`,
      `ConcussionFollowUp3-${id}`,
      `ConcussionFollowUp4-${id}`,
      `ConcussionFollowUp5-${id}`,
      `ConcussionFollowUp6-${id}`,
    ];

    const storedKeys = Object.keys(localStorage).filter((key) =>
      key.includes(`-${id}`)
    );

    const missingForms = expectedForms.filter(
      (form) => !storedKeys.includes(form)
    );

    if (missingForms.length > 0) {
      toast.error("Please go through all the Follow-Up forms");
      return;
    }

    const aggregatedData = expectedForms.reduce((acc, key) => {
      const formData = JSON.parse(localStorage.getItem(key)) || {};
      return { ...acc, ...formData, ...values };
    }, {});

    dispatch(createConcussionForm(token, aggregatedData))
      .then(() => {
        toast.success("Concussion Follow-UP Form submitted successfully");
        expectedForms.forEach((key) => localStorage.removeItem(key));
        history.push("/concussion-forms");
      })
      .catch((error) => {
        toast.error("Error! " + error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Current Injury</span>
        <span className="form-progress">7/7</span>
      </div>

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, values, setFieldValue }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper">
                <label className="field-label">
                  Total Number of Symptoms:{" "}
                  <span className="score-label">
                    {initialValues.total_symptoms}/22&nbsp;&nbsp; (
                    {((initialValues.total_symptoms / 22) * 100).toFixed(1)}%)
                  </span>
                </label>
                <label className="field-label">
                  Symptom Severity Score:{" "}
                  <span className="score-label1">
                    {initialValues.symptom_severity_score}/132 (
                    {(
                      (initialValues.symptom_severity_score / 132) *
                      100
                    ).toFixed(1)}
                    %)
                  </span>
                </label>
              </div>
              <div className="field-wrapper">
                <label className="field-label">
                  Do your symptoms get worse with physical activity?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="worse_with_physical_activity">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "worse_with_physical_activity",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="worse_with_physical_activity"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  Do your symptoms get worse with mental activity?
                </label>
                <div role="group" className="tabs-group">
                  {CHOICES.map((option) => (
                    <Field name="worse_with_mental_activity">
                      {({ field, form }) => (
                        <p
                          className={`tab  ${
                            field.value === option.value ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue(
                              "worse_with_mental_activity",
                              option.value
                            )
                          }
                        >
                          {option.label}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="worse_with_mental_activity"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">
                  If 100% is feeling perfectly normal, what percent of normal do
                  you feel?
                </label>
                <div className="slider-container">
                  <input
                    type="range"
                    name="percent_feeling_normal"
                    min="0"
                    max="100"
                    step="1"
                    value={values.percent_feeling_normal}
                    onChange={(e) =>
                      setFieldValue("percent_feeling_normal", e.target.value)
                    }
                    className="field-input"
                  />
                  <span className="slider-percentage">
                    {values.percent_feeling_normal}%
                  </span>{" "}
                </div>
                <ErrorMessage
                  name="percent_feeling_normal"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Please explain.</label>
                <Field
                  name="symptoms_explanation"
                  className="field-input notes"
                  as="textarea"
                />
                <ErrorMessage
                  name="symptoms_explanation"
                  component="div"
                  className="error-message"
                />
              </div>

              <div role="group" aria-labelledby="checkbox-group">
                <label className="option-label">
                  <Field
                    type="checkbox"
                    name="subject_cleared_in_visit"
                    className="checkbox-input"
                  />
                  Subject cleared from concussion at this visit
                </label>
              </div>
            </div>

            <div className="btn-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() =>
                  history.push(`/concussion-forms/followup/6/${id}`)
                }
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                {"FINISH"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CurrentInjury7;
