import React, { Fragment, useMemo, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { ArrowUpward, ArrowDownward, UnfoldMore } from "@mui/icons-material";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import Pagination from "@mui/material/Pagination";
import CircularLabelLoader from "../loader/CircularLabelLoader";
import "./reactTable.css";
import {
  useTable,
  usePagination,
  useExpanded,
  useSortBy,
  useRowSelect,
} from "react-table";
import { formatTime, formatDate } from "../../helper";
import tickIcon from "../../assets/img/svg/tick-rounded.svg";

const genderMap = {
  1: "M",
  2: "F",
};
const conditionMap = {
  1: "seizure",
  2: "concussion",
  3: "stroke",
  4: "Normal",
  5: "Altered Mental Status",
  0: "0",
};

const SETTINGS = {
  1: "Clinic",
  2: "ED",
  3: "ICU",
};

const KEY_NOTES = {
  1: "Baseline",
  2: "Follow-up",
  3: "Normal",
  4: "PDR",
  5: "SSVEP",
  6: "P300",
  7: "Ictal",
  8: "Pre-ictal",
  9: "Sine Wave",
  10: "Square Wave",
  11: "Triangle Wave",
  12: "Test Dataset",
  13: "OMIT",
};

const formCategory = {
  first_visit: "First Visit",
  followup: "Follow Up",
};

// Checkbox component for row selection
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    return <input type="checkbox" ref={resolvedRef} {...rest} />;
  }
);

const Table = ({
  columns,
  data,
  params,
  // fetchData,
  // pageCount: controlledPageCount,
  loading,
  isPaginated,
  tableOf,
  tableType,
  onSelectedRowsChange,
  progress,
  handleDeleteVisitDetails,
  patientVisitsDetailValue,
}) => {
  const defaultColumn = useMemo(() => ({}), []);

  // Add a checkbox column at the beginning
  const columnsWithCheckbox = useMemo(
    () => [
      {
        id: "selection",
        Header: ({ getToggleAllPageRowsSelectedProps }) => (
          <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
        ),
        Cell: ({ row }) => (
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        ),
        disableSortBy: true,
      },
      ...columns,
    ],
    [columns]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    selectedFlatRows,
    toggleRowSelected,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns: columnsWithCheckbox,
      data,
      defaultColumn,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
        hiddenColumns: columns
          ?.filter((column) => !column?.show)
          ?.map((column) => column?.id),
      },
      // manualPagination: true,
      autoResetPage: true,
      // pageCount: controlledPageCount,
      // manualSortBy: true,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const prevSelectedRowIds = useRef([]);
  const history = useHistory();

  useEffect(() => {
    const currentSelectedRowIds = selectedFlatRows.map(
      (row) => row.original.id
    );

    // Compare the current selected row IDs with the previous ones
    const hasSelectionChanged =
      prevSelectedRowIds.current.length !== currentSelectedRowIds.length ||
      prevSelectedRowIds.current.some(
        (id, index) => id !== currentSelectedRowIds[index]
      );

    if (hasSelectionChanged && onSelectedRowsChange) {
      // Trigger the callback with the new selected row IDs
      onSelectedRowsChange(currentSelectedRowIds);
    }

    // Update the ref to the current selected row IDs
    prevSelectedRowIds.current = currentSelectedRowIds;
  }, [selectedFlatRows, onSelectedRowsChange]);

  // useEffect(() => {
  //   fetchData && fetchData({ pageIndex, pageSize, sortBy });
  // }, [fetchData, pageIndex, pageSize, sortBy]);

  useEffect(() => {
    gotoPage(0);
  }, [gotoPage]);

  return (
    <Fragment>
      <div className="list-table">
        {loading ? (
          <CircularLabelLoader value={progress} />
        ) : (
          <table
            {...getTableProps()}
            className="table"
            style={{ width: "unset", minWidth: "100%" }}
          >
            <thead>
              {headerGroups?.map((headerGroup) => (
                <tr
                  style={{ borderBottom: "1px solid #E2E2E2" }}
                  {...headerGroup?.getHeaderGroupProps()}
                >
                  {headerGroup?.headers?.map((column) => (
                    <th
                      className="whiteSpacesClass"
                      {...column.getHeaderProps(column?.getSortByToggleProps())}
                      style={{
                        padding: "6px",
                        fontSize: "14px",
                      }}
                    >
                      <b>{column?.render("Header")}</b>
                      <span>
                        {column?.isSorted ? (
                          column?.isSortedDesc ? (
                            <ArrowUpward style={{ fontSize: 16 }} />
                          ) : (
                            <ArrowDownward style={{ fontSize: 16 }} />
                          )
                        ) : column?.canSort ? (
                          <UnfoldMore style={{ fontSize: 16 }} />
                        ) : null}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            {data?.length > 0 ? (
              <tbody {...getTableBodyProps()}>
                {page?.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.id}>
                      <tr className="" {...row?.getRowProps()}>
                        {row?.cells?.map((cell, cellIndex) => (
                          <td
                            {...cell?.getCellProps()}
                            style={{
                              padding: "6px",
                              fontSize: "14px",
                              verticalAlign: "middle",
                            }}
                          >
                            <div
                              onClick={() =>
                                cellIndex === 1 ? row.toggleRowExpanded() : null
                              }
                            >
                              {cell?.render("Cell")}
                            </div>
                          </td>
                        ))}
                      </tr>
                      {row.isExpanded &&
                      (tableOf === "PatientVisits" ||
                        tableOf === "PatientVisitsDetail") ? (
                        <tr className="border-top-grey">
                          <td colSpan={columns.length + 1}>
                            <table className="table table-expanded">
                              <thead>
                                <tr>
                                  <th>Location</th>
                                  <th>Key Notes</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {row.original.details.length > 0 ? (
                                  row.original.details.map(
                                    (expandedRow, index) => (
                                      <tr key={index}>
                                        <td>{expandedRow.location}</td>
                                        {/* <td>0:40</td>
                                        <td>Acceptable</td> */}
                                        <td>
                                          {
                                            KEY_NOTES[
                                              row.original.patient
                                                .clinical_keynote ??
                                                patientVisitsDetailValue
                                            ]
                                          }
                                        </td>
                                        <td>
                                          {tableOf ===
                                            "PatientVisitsDetail" && (
                                            <button
                                              className="action-button delete"
                                              onClick={() =>
                                                handleDeleteVisitDetails(
                                                  expandedRow.id
                                                )
                                              }
                                            >
                                              DELETE
                                            </button>
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <td>Not any record found</td>
                                )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      ) : null}
                      {row.isExpanded && tableOf === "Patients" ? (
                        <tr className="border-top-grey">
                          <td colSpan="4">
                            <div class="container patient-row">
                              <div class="row ms-auto">
                                <div class="col-md-4">
                                  <strong className="patient-row-head">
                                    Patient#:{" "}
                                  </strong>{" "}
                                  {row.original.id_number}
                                </div>
                                <div class="col-md-4 ml-4">
                                  <strong className="patient-row-head">
                                    Condition:
                                  </strong>{" "}
                                  {
                                    conditionMap[
                                      row.original.clinical_condition
                                    ]
                                  }
                                </div>
                              </div>

                              <div class="row ms-auto mt-4">
                                <div class="col-md-4">
                                  <strong className="patient-row-head">
                                    Gender:
                                  </strong>{" "}
                                  {genderMap[row.original.gender]}
                                </div>
                                <div class="col-md-4 ml-4">
                                  <strong className="patient-row-head">
                                    Age:
                                  </strong>{" "}
                                  {row.original.age}
                                </div>
                              </div>

                              <div class="row mt-2 ms-auto mt-4">
                                <div
                                  class="col-md-4"
                                  className="patient-row-head"
                                >
                                  <Link
                                    to={`/listrecordings/${row.original.id}`}
                                    className="text-decoration-underline text-dark"
                                  >
                                    View all visits
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td colSpan={columns.length + 1}>
                            <table className="table table-expanded">
                              <thead>
                                <tr>
                                  <th>Date of Visit</th>
                                  <th>Time</th>
                                  <th>Setting</th>
                                  <th>Key Notes</th>
                                </tr>
                              </thead>
                              <tbody>
                                {row.original?.recent_visits?.length > 0 ? (
                                  row.original.recent_visits.map(
                                    (visit, index) => (
                                      <tr key={index}>
                                        <td>{formatDate(visit?.timestamp)}</td>
                                        <td>{formatTime(visit?.timestamp)}</td>
                                        <td>
                                          {
                                            SETTINGS[
                                              row.original.clinical_setting
                                            ]
                                          }
                                        </td>
                                        <td>
                                          {
                                            KEY_NOTES[
                                              row.original.clinical_keynote
                                            ]
                                          }
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <td>Not any recent visit</td>
                                )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      ) : null}
                      {/* expanded row of concussion forms */}
                      {row.isExpanded && tableOf === "ConcussionForms" ? (
                        <tr className="border-top-grey">
                          <td colSpan={columns.length + 1}>
                            <table className="table table-expanded">
                              <thead>
                                <tr>
                                  <th>Date of Examination</th>
                                  <th>Current School Year</th>
                                  <th>Years of Education Completed</th>
                                  <th>Score</th>
                                  <th>Subject Cleared</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {row.original?.first_visit?.follow_ups?.length >
                                0 ? (
                                  row.original?.first_visit?.follow_ups?.map(
                                    (visit, index) => (
                                      <tr key={index}>
                                        <td>
                                          {visit?.date_of_test
                                            ? formatDate(visit?.date_of_test)
                                            : "N/A"}
                                        </td>
                                        <td>{visit?.current_year_in_school}</td>
                                        <td>
                                          {visit?.years_education_completed}
                                        </td>
                                        <td>{visit?.symptom_severity_score}</td>
                                        <td>
                                          {visit?.subject_cleared_in_visit
                                            ? "Yes"
                                            : "No"}
                                        </td>
                                        <td>
                                          <Link
                                            to={`/concussion-form/followup/${visit?.id}`}
                                          >
                                            View
                                          </Link>
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <td>Not any Follow-up</td>
                                )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      ) : null}
                    </Fragment>
                  );
                })}
              </tbody>
            ) : (
              <tbody {...getTableBodyProps()}>
                <tr>
                  <td colSpan={columns?.length} className="text-center">
                    {tableOf === "ConcussionForms" ||
                    tableOf === "SwayForms" ? (
                      <AddCircleOutlinedIcon
                        fontSize="large"
                        className="add-circle-btn"
                        onClick={() =>
                          history.push(
                            tableOf === "ConcussionForms"
                              ? "/concussion-forms/1"
                              : "/sway-forms/1"
                          )
                        }
                      />
                    ) : tableOf === "PatientVisits" &&
                      tableType === "review" ? (
                      <div className="flex flex-col items-center justify-center py-12">
                        <div className="text-align-center mb-2">
                          <img src={tickIcon} width={50} />
                        </div>
                        <h3 className="text-grey-xl font-medium text-gray-600 mb-1">
                          All caught up!
                        </h3>
                        <p className="text-600">
                          You've reviewed all your visits. Check back when you
                          have more patient follow-ups.
                        </p>
                      </div>
                    ) : (
                      <div className="mt-4">Not any record found</div>
                    )}
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        )}
        {loading ? (
          <div></div>
        ) : (
          <div className="pagination d-flex justify-content-end mb-3">
            <Pagination
              count={pageCount}
              page={pageIndex + 1}
              onChange={(event, value) => {
                gotoPage(value - 1);
              }}
              variant="outlined"
              shape="rounded"
              color="primary"
            />
            {/* <div className="d-flex align-items-center">
              {pageIndex + 1 > 1 && (
                <p
                  className="page-btn me-4"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  {"< Previous Page"}
                </p>
              )}

              <div className="input-container">
                <span className="input-label">{pageIndex + 1}</span>
                <input
                  type="number"
                  className="page-search-input"
                  onChange={(e) => {
                    let pageValue = e.target.value;
                    // Prevent triggering gotoPage if the input is empty
                    if (pageValue === "") return;
                    const pageNumber = Math.max(
                      0,
                      Math.min(pageCount - 1, Number(pageValue) - 1)
                    );
                    gotoPage(pageNumber);
                  }}
                />
              </div>
              {pageIndex < pageOptions.length - 1 && (
                <p
                  className="page-btn m-4"
                  onClick={() => nextPage()}
                  disabled={!canPreviousPage}
                >
                  {"Next Page >"}
                </p>
              )}
            </div> */}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Table;
