import axios from "axios";
import {
  DOCTOR_LIST_REQUEST,
  DOCTOR_LIST_SUCCESS,
  DOCTOR_LIST_FAIL,
  PATIENT_VISITS_DETAIL_REQUEST,
  PATIENT_VISITS_DETAIL_SUCCESS,
  PATIENT_VISITS_DETAIL_FAIL,
  HOSPITAL_LIST_REQUEST,
  HOSPITAL_LIST_SUCCESS,
  HOSPITAL_LIST_FAIL,
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_FAIL,
  READ_NOTIFICATION_REQUEST,
  READ_NOTIFICATION_SUCCESS,
  READ_NOTIFICATION_FAIL,
  MARK_ALL_NOTIFICATIONS_READ_REQUEST,
  MARK_ALL_NOTIFICATIONS_READ_SUCCESS,
  MARK_ALL_NOTIFICATIONS_READ_FAIL,
} from "../constants/doctorConstants";

export const listDoctors = () => async (dispatch) => {
  try {
    dispatch({ type: DOCTOR_LIST_REQUEST });
    const { data } = await axios.get(`http://127.0.0.1:8000/doc/docs/`);

    dispatch({
      type: DOCTOR_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DOCTOR_LIST_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.detail,
    });
  }
};

export const getPatientVisitsDetail = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: PATIENT_VISITS_DETAIL_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/patient_visit_detail_v1/${id}`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: PATIENT_VISITS_DETAIL_SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: PATIENT_VISITS_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};

export const getHospitalList = (token) => async (dispatch) => {
  try {
    dispatch({ type: HOSPITAL_LIST_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/hospital`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: HOSPITAL_LIST_SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: HOSPITAL_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};

export const getNotifications = (token) => async (dispatch) => {
  try {
    dispatch({ type: NOTIFICATIONS_REQUEST });

    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/notifications`,
      {
        headers: {
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: NOTIFICATIONS_SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: NOTIFICATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};

export const readNotification = (id, token) => async (dispatch) => {
  try {
    dispatch({ type: READ_NOTIFICATION_REQUEST });

    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/notifications/mark-read`,
      {
        notification_id: id,
      },
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: READ_NOTIFICATION_SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: READ_NOTIFICATION_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};

export const markAllReadNotifications = (token) => async (dispatch) => {
  try {
    dispatch({ type: MARK_ALL_NOTIFICATIONS_READ_REQUEST });

    const { data } = await axios.put(
      `${process.env.REACT_APP_BASE_URL}/notifications`,
      {},
      {
        headers: {
          "Content-type": "application/json",
          Authorization: `${token}`,
        },
      }
    );
    dispatch({
      type: MARK_ALL_NOTIFICATIONS_READ_SUCCESS,
      payload: data.data,
    });
    return data.data;
  } catch (error) {
    dispatch({
      type: MARK_ALL_NOTIFICATIONS_READ_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
    throw error;
  }
};
