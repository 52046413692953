import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  getConcussionForm,
  updateConcussionForm,
} from "../../../actions/patientActions";
import { toast } from "react-toastify";

const CHOICES = [
  { value: "Y", label: "Yes" },
  { value: "N", label: "No" },
];

const validationSchema = Yup.object().shape({
  hours_per_night_since_injury: Yup.number()
    .typeError("Sleep Hours must be a number")
    .nullable(),
  screen_time_hours_per_day: Yup.number()
    .typeError("Screen Time must be a number")
    .nullable(),
});
const SocialHistory = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [save, setSave] = useState(false);
  const token = useSelector((state) => state.loginData.token);
  const concussionFormData = useSelector((state) => state.concussionForm);
  const { concussionForm = {} } = concussionFormData;

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error fetching data: " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id, history]);

  const defaultValues = {
    eating_habits_changed: "N",
    eating_habits_explanation: "",
    hours_per_night_since_injury: "",
    screen_time_hours_per_day: "",
    medications: [],
    nameInput: "",
    doseInput: "",
  };

  const storedForm = JSON.parse(
    localStorage.getItem(`ConcussionFollowUp2-${id}`)
  );
  const initialValues = { ...defaultValues, ...storedForm };

  const handleSubmit = (values, { setSubmitting }) => {
    localStorage.setItem(`ConcussionFollowUp2-${id}`, JSON.stringify(values));
    history.push(`/concussion-forms/followup/3/${id}`);
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Social History</span>
        <span className="form-progress">2/7</span>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, setFieldValue, handleChange }) => {
          const addMedication = () => {
            const name = values.nameInput;
            const dose = values.doseInput;

            if (name.trim() && dose.trim()) {
              setFieldValue("medications", [
                ...values.medications,
                { name, dose },
              ]);
              setFieldValue("nameInput", "");
              setFieldValue("doseInput", "");
            }
          };

          const removeMedication = (index) => {
            const updatedMedications = values.medications.filter(
              (_, i) => i !== index
            );
            setFieldValue("medications", updatedMedications);
          };
          return (
            <Form
              className="form-wrapper"
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.target.nodeName === "INPUT") {
                  e.preventDefault();
                }
              }}
            >
              <div className="form-content">
                <div className="field-wrapper">
                  <label className="field-label">
                    Hours of sleep per night since injury?
                  </label>
                  <Field
                    name="hours_per_night_since_injury"
                    className="field-input data-field"
                  />
                  <ErrorMessage
                    name="hours_per_night_since_injury"
                    component="div"
                    className="error-message"
                  />
                </div>

                <div className="field-wrapper">
                  <label className="field-label">
                    Hour of screen time per day?
                  </label>
                  <Field
                    name="screen_time_hours_per_day"
                    className="field-input data-field"
                  />
                  <ErrorMessage
                    name="screen_time_hours_per_day"
                    component="div"
                    className="error-message"
                  />
                </div>

                <div className="field-wrapper">
                  <label className="field-label">
                    Have your eating habits changed since your injury?
                  </label>
                  <div role="group" className="tabs-group">
                    {CHOICES.map((option) => (
                      <Field name="eating_habits_changed" key={option.value}>
                        {({ field, form }) => (
                          <p
                            className={`tab ${
                              field.value === option.value ? "active" : ""
                            }`}
                            onClick={() =>
                              form.setFieldValue(
                                "eating_habits_changed",
                                option.value
                              )
                            }
                          >
                            {option.label}
                          </p>
                        )}
                      </Field>
                    ))}
                  </div>
                  <ErrorMessage
                    name="eating_habits_changed"
                    component="div"
                    className="error-message"
                  />
                </div>

                <div className="field-wrapper">
                  <label className="field-label">Please explain</label>
                  <Field
                    name="eating_habits_explanation"
                    className="field-input notes"
                    as="textarea"
                  />
                  <ErrorMessage
                    name="eating_habits_explanation"
                    component="div"
                    className="error-message"
                  />
                </div>

                <div className="field-wrapper">
                  <label className="field-label">
                    Which medications are you taking?
                  </label>
                  <Field
                    name="nameInput"
                    className="field-input"
                    value={values.nameInput || ""}
                    onChange={(e) => setFieldValue("nameInput", e.target.value)}
                  />
                  <ErrorMessage
                    name="nameInput"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="field-wrapper">
                  <label className="field-label">Dose</label>
                  <Field
                    name="doseInput"
                    className="field-input"
                    placeholder="Type a dose"
                    value={values.doseInput || ""}
                    onChange={handleChange}
                  />
                  <button
                    type="button"
                    className="add-btn"
                    onClick={addMedication}
                  >
                    +
                  </button>
                  {values.medications.length > 0 && (
                    <div className="dose-list">
                      {values.medications.map((med, index) => (
                        <div key={index} className="dose-item">
                          <span className="dose-text">
                            {med.name} ({med.dose})
                          </span>
                          <button
                            type="button"
                            className="remove-button"
                            onClick={() => removeMedication(index)}
                          >
                            x
                          </button>
                        </div>
                      ))}
                    </div>
                  )}

                  <ErrorMessage
                    name="dose"
                    component="div"
                    className="error-message"
                  />
                </div>
              </div>
              <div className="btns-wrapper">
                <button
                  type="button"
                  className="form-btns back-btn"
                  disabled={isSubmitting}
                  onClick={() =>
                    history.push(`/concussion-forms/followup/1/${id}`)
                  }
                >
                  BACK
                </button>
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                >
                  NEXT
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SocialHistory;
