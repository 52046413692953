export const PATIENT_CREATE_REQUEST = "PATIENT_CREATE_REQUEST";
export const PATIENT_CREATE_SUCCESS = "PATIENT_CREATE_SUCCESS";
export const PATIENT_CREATE_FAIL = "PATIENT_CREATE_FAIL";
export const PATIENT_CREATE_RESET = "PATIENT_CREATE_RESET";

export const PATIENT_DETAIL_REQUEST = "PATIENT_CREATE_REQUEST";
export const PATIENT_DETAIL_SUCCESS = "PATIENT_CREATE_SUCCESS";
export const PATIENT_DETAIL_FAIL = "PATIENT_CREATE_FAIL";

export const PATIENT_UPDATE_REQUEST = "PATIENT_CREATE_REQUEST";
export const PATIENT_UPDATE_SUCCESS = "PATIENT_CREATE_SUCCESS";
export const PATIENT_UPDATE_FAIL = "PATIENT_CREATE_FAIL";

export const PATIENT_LIST_REQUEST = "PATIENT_LIST_REQUEST";
export const PATIENT_LIST_SUCCESS = "PATIENT_LIST_SUCCESS";
export const PATIENT_LIST_FAIL = "PATIENT_LIST_FAIL";

export const PATIENTVISIT_CREATE_REQUEST = "PATIENTVISIT_CREATE_REQUEST";
export const PATIENTVISIT_CREATE_SUCCESS = "PATIENTVISIT_CREATE_SUCCESS";
export const PATIENTVISIT_CREATE_FAIL = "PATIENTVISIT_CREATE_FAIL";
export const PATIENTVISIT_CREATE_RESET = "PATIENTVISIT_CREATE_RESET";

export const PATIENTVISIT_LIST_REQUEST = "PATIENTVISIT_LIST_REQUEST";
export const PATIENTVISIT_LIST_SUCCESS = "PATIENTVISIT_LIST_SUCCESS";
export const PATIENTVISIT_LIST_FAIL = "PATIENTVIST_LIST_FAIL";

export const PATIENT_RECENT_VISIT_LIST_REQUEST =
  "PATIENT_RECENT_VISIT_LIST_REQUEST";
export const PATIENT_RECENT_VISIT_LIST_SUCCESS =
  "PATIENT_RECENT_VISIT_LIST_SUCCESS";
export const PATIENT_RECENT_VISIT_LIST_FAIL = "PATIENT_RECENT_VISIT_LIST_FAIL";

export const PATIENT_VISIT_DELETE_REQUEST = "PATIENTVISIT_CREATE_REQUEST";
export const PATIENT_VISIT_DELETE_SUCCESS = "PATIENTVISIT_CREATE_SUCCESS";
export const PATIENT_VISIT_DELETE_FAIL = "PATIENTVISIT_CREATE_FAIL";

export const PATIENT_VISIT_DETAIL_REQUEST = "PATIENT_VISIT_DETAIL_REQUEST";
export const PATIENT_VISIT_DETAIL_SUCCESS = "PATIENT_VISIT_DETAIL_SUCCESS";
export const PATIENT_VISIT_DETAIL_FAIL = "PATIENT_VISIT_DETAIL_FAIL";

export const VISIT_STATUS_UPDATE_REQUEST = "VISIT_STATUS_UPDATE_REQUEST";
export const VISIT_STATUS_UPDATE_SUCCESS = "VISIT_STATUS_UPDATE_SUCCESS";
export const VISIT_STATUS_UPDATE_FAIL = "VISIT_STATUS_UPDATE_FAIL";

export const CSV_FILE_REQUEST = "CSV_FILE_REQUEST";
export const CSV_FILE_SUCCESS = "CSV_FILE_SUCCESS";
export const CSV_FILE_FAIL = "CSV_FILE_FAIL";

export const BULK_PATIENT_VISIT_DELETE_REQUEST =
  "BULK_PATIENT_VISIT_DELETE_REQUEST";
export const BULK_PATIENT_VISIT_DELETE_SUCCESS =
  "BULK_PATIENT_VISIT_DELETE_SUCCESS";
export const BULK_PATIENT_VISIT_DELETE_FAIL = "BULK_PATIENT_VISIT_DELETE_FAIL";

export const CONCUSSION_FORMS_REQUEST = "CONCUSSION_FORMS_REQUEST";
export const CONCUSSION_FORMS_SUCCESS = "CONCUSSION_FORMS_SUCCESS";
export const CONCUSSION_FORMS_FAIL = "CONCUSSION_FORMS_FAIL";

export const CONCUSSION_FORM_REQUEST = "CONCUSSION_FORM_REQUEST";
export const CONCUSSION_FORM_SUCCESS = "CONCUSSION_FORM_SUCCESS";
export const CONCUSSION_FORM_FAIL = "CONCUSSION_FORM_FAIL";

export const CONCUSSION_FORM_CREATE_REQUEST = "CONCUSSION_FORM_CREATE_REQUEST";
export const CONCUSSION_FORM_CREATE_SUCCESS = "CONCUSSION_FORM_CREATE_SUCCESS";
export const CONCUSSION_FORM_CREATE_FAIL = "CONCUSSION_FORM_CREATE_FAIL";

export const CONCUSSION_FORM_UPDATE_REQUEST = "CONCUSSION_FORM_UPDATE_REQUEST";
export const CONCUSSION_FORM_UPDATE_SUCCESS = "CONCUSSION_FORM_UPDATE_SUCCESS";
export const CONCUSSION_FORM_UPDATE_FAIL = "CONCUSSION_FORM_UPDATE_FAIL";

export const CONCUSSION_FORM_DELETE_REQUEST = "CONCUSSION_FORM_DELETE_REQUEST";
export const CONCUSSION_FORM_DELETE_SUCCESS = "CONCUSSION_FORM_DELETE_SUCCESS";
export const CONCUSSION_FORM_DELETE_FAIL = "CONCUSSION_FORM_DELETE_FAIL";

export const SWAY_FORM_CREATE_REQUEST = "SWAY_FORM_CREATE_REQUEST";
export const SWAY_FORM_CREATE_SUCCESS = "SWAY_FORM_CREATE_SUCCESS";
export const SWAY_FORM_CREATE_FAIL = "SWAY_FORM_CREATE_FAIL";

// Update Sway Form
export const SWAY_FORM_UPDATE_REQUEST = "SWAY_FORM_UPDATE_REQUEST";
export const SWAY_FORM_UPDATE_SUCCESS = "SWAY_FORM_UPDATE_SUCCESS";
export const SWAY_FORM_UPDATE_FAIL = "SWAY_FORM_UPDATE_FAIL";

// Delete Sway Form
export const SWAY_FORM_DELETE_REQUEST = "SWAY_FORM_DELETE_REQUEST";
export const SWAY_FORM_DELETE_SUCCESS = "SWAY_FORM_DELETE_SUCCESS";
export const SWAY_FORM_DELETE_FAIL = "SWAY_FORM_DELETE_FAIL";

// Fetch Single Sway Form
export const SWAY_FORM_REQUEST = "SWAY_FORM_REQUEST";
export const SWAY_FORM_SUCCESS = "SWAY_FORM_SUCCESS";
export const SWAY_FORM_FAIL = "SWAY_FORM_FAIL";

// Fetch Multiple Sway Forms
export const SWAY_FORMS_REQUEST = "SWAY_FORMS_REQUEST";
export const SWAY_FORMS_SUCCESS = "SWAY_FORMS_SUCCESS";
export const SWAY_FORMS_FAIL = "SWAY_FORMS_FAIL";
