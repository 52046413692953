import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  listPatient,
  getConcussionForm,
  updateConcussionForm,
} from "../../../actions/patientActions";
import { calculateAge } from "../../../helper";

const validationSchema = Yup.object().shape({
  id_number: Yup.string().required("Please enter a valid ID Number"),
  years_education_completed: Yup.number()
    .nullable()
    .typeError("Years of Education Completed must be in numbers"),
});

const ConcussionIntakeForm = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const patientList = useSelector((state) => state.patientList);
  const concussionFormData = useSelector((state) => state.concussionForm);
  const token = useSelector((state) => state.loginData.token);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPatients, setFilteredPatients] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [age, setAge] = useState("");
  const [save, setSave] = useState(false);

  const { patients = [] } = patientList;
  const { concussionForm = [] } = concussionFormData;

  useEffect(() => {
    if (patients && patients.length) {
      const filtered = patients
        .map((patient) => ({
          ...patient,
          isMatch: String(patient.id_number)
            .toLowerCase()
            .includes(String(searchTerm).toLowerCase()),
        }))
        .sort((a, b) => b.isMatch - a.isMatch);
      setFilteredPatients(filtered);
    }
  }, [searchTerm, patients]);

  const defaultValues = {
    patient: "",
    id_number: "",
    age: "",
    gender: null,
    date_of_injury: null,
    dominant_hand: null,
    date_of_test: null,
    subject_group: "Cohort",
    current_year_in_school: "",
    years_education_completed: "",
  };
  const storedForm = JSON.parse(localStorage.getItem("ConcussionForm1"));
  const initialValues = id
    ? { ...defaultValues, ...concussionForm }
    : { ...defaultValues, ...storedForm };

  useEffect(() => {
    if (id) {
      dispatch(getConcussionForm(token, id)).catch((error) => {
        toast.error("Error! " + error.message);
        history.push("/concussion-forms");
      });
    }
  }, [dispatch, token, id]);

  useEffect(() => {
    dispatch(listPatient(token));
  }, [dispatch, token]);

  const handleSubmit = (values, { setSubmitting }) => {
    const validId = patients.some(
      (patient) => patient?.id_number === values.id_number
    );

    if (validId) {
      if (id) {
        dispatch(updateConcussionForm(token, id, values))
          .then(() => {
            if (save) {
              history.push(`/concussion-forms`);
              toast.success("Concussion Form Updated Successfully");
            } else {
              history.push(`/concussion-forms/2/${id}`);
            }
          })
          .catch((error) => {
            toast.error("Error! " + error.message);
            setSubmitting(false);
          });
      } else {
        localStorage.setItem("ConcussionForm1", JSON.stringify(values));
        history.push(`/concussion-forms/2`);
      }
    } else {
      toast.error("Please select a valid ID Number");
      setSubmitting(false);
    }
  };

  return (
    <div className="form-container">
      <div className="form-header">
        <span className="form-title">Concussion Intake Form</span>
        <span className="form-progress">1/12</span>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form className="form-wrapper">
            <div className="form-content">
              <div className="field-wrapper" style={{ position: "relative" }}>
                <label className="field-label">ID Number</label>
                <Field
                  name="id_number"
                  className="field-input"
                  placeholder="Search by ID Number"
                  value={values.id_number ?? searchTerm}
                  onChange={(event) => {
                    const { value } = event.target;
                    setSearchTerm(value);
                    setFieldValue("id_number", value);
                    setShowDropdown(true);
                  }}
                  onFocus={() => setShowDropdown(true)}
                  onBlur={() => setTimeout(() => setShowDropdown(false), 150)}
                />
                {showDropdown && filteredPatients.length > 0 && (
                  <ul className="dropdown-list">
                    {filteredPatients.map((patient) => (
                      <li
                        key={patient.id_number}
                        className="dropdown-item"
                        onClick={() => {
                          setSearchTerm(patient.id_number);
                          setFieldValue("id_number", patient.id_number);
                          setFieldValue("patient", patient.id);
                          setFieldValue("age", calculateAge(patient.dob));
                          setShowDropdown(false);
                        }}
                      >
                        {`${patient.id_number}`}
                      </li>
                    ))}
                  </ul>
                )}
                {!searchTerm && (
                  <ErrorMessage
                    name="id_number"
                    component="div"
                    className="error-message"
                  />
                )}
              </div>

              <div className="field-wrapper">
                <label className="field-label">Age</label>
                <Field name="age" className="field-input" disabled />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Gender</label>
                <Field as="select" name="gender" className="field-input">
                  <option value="" className="inputOption">
                    Select Gender
                  </option>
                  <option value="M">Male</option>
                  <option value="F">Female</option>
                </Field>
                <ErrorMessage
                  name="gender"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Date of Injury</label>
                <Field
                  name="date_of_injury"
                  type="date"
                  className="field-input"
                />
                <ErrorMessage
                  name="date_of_injury"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Dominant Hand</label>
                <Field as="select" name="dominant_hand" className="field-input">
                  <option value="">Select Dominant Hand</option>
                  <option value="R">Right</option>
                  <option value="L">Left</option>
                  <option value="A">Ambidextrous</option>
                </Field>
                <ErrorMessage
                  name="dominant_hand"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">Date of Examination</label>
                <Field
                  name="date_of_test"
                  type="date"
                  className="field-input data-field"
                />
                <ErrorMessage
                  name="date_of_test"
                  component="div"
                  className="error-message"
                />
              </div>

              <div className="field-wrapper">
                <label className="field-label">Subject Group</label>
                <div role="group" className="tabs-group">
                  {["Cohort", "Control"].map((option) => (
                    <Field name="subject_group" key={option}>
                      {({ field, form }) => (
                        <p
                          className={`tab ${
                            field.value === option ? "active" : ""
                          }`}
                          onClick={() =>
                            form.setFieldValue("subject_group", option)
                          }
                        >
                          {option}
                        </p>
                      )}
                    </Field>
                  ))}
                </div>
                <ErrorMessage
                  name="subject_group"
                  component="div"
                  className="error-message"
                />
              </div>

              {/* <div className="field-wrapper">
                <label className="field-label">Visit Type</label>
                <Field as="select" name="form_category" className="field-input">
                  <option value="" className="options-font">
                    Select Visit Type
                  </option>
                  <option value="first_visit" className="options-font">
                    First Visit
                  </option>
                  <option value="followup" className="options-font">
                    Follow Up
                  </option>
                </Field>
                <ErrorMessage
                  name="form_category"
                  component="div"
                  className="error-message"
                />
              </div> */}

              {/* <div className="field-wrapper">
                <label className="field-label">Notes</label>
                <Field
                  name="notes"
                  className="field-input notes"
                  as="textarea"
                />
                <ErrorMessage
                  name="notes"
                  component="div"
                  className="error-message"
                />
              </div> */}

              <div className="field-wrapper">
                <label className="field-label">Current Year in School</label>
                <Field name="current_year_in_school" className="field-input" />
                <ErrorMessage
                  name="current_year_in_school"
                  component="div"
                  className="error-message"
                />
              </div>
              <div className="field-wrapper">
                <label className="field-label">
                  Years of Education Completed
                </label>
                <Field
                  name="years_education_completed"
                  className="field-input"
                />
                <ErrorMessage
                  name="years_education_completed"
                  component="div"
                  className="error-message"
                />
              </div>
            </div>

            <div className="btns-wrapper">
              <button
                type="button"
                className="form-btns back-btn"
                disabled={isSubmitting}
                onClick={() => history.push("/concussion-forms")}
              >
                BACK
              </button>
              <button
                type="submit"
                className="form-btns next-btn"
                disabled={isSubmitting}
              >
                NEXT
              </button>
              {id && (
                <button
                  type="submit"
                  className="form-btns next-btn"
                  disabled={isSubmitting}
                  onClick={() => setSave(true)}
                >
                  SAVE
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ConcussionIntakeForm;
