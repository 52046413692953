import dayjs from "dayjs";

export const calculateAge = (dob) => {
  return new Date().getFullYear() - new Date(dob).getFullYear();
};

export const formatDate = (date) => {
  return dayjs(date).format("MMM-DD-YYYY");
};

export const formatTime = (date) => {
  return dayjs(date).format("HH:mm:ss");
};

export const formatDateTime = (date) => {
  return dayjs(date).format("MMM-DD-YYYY HH:mm:ss");
};

export const formatDOB = (date) => {
  return dayjs(date).format("DD-MM-YYYY");
};

export const formatName = (name) => {
  if (!name) return "";
  return name
    .toLowerCase()
    .split(" ")
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(" ");
};

export const calculateScore = () => {
  const allFormKeys = [
    "ConcussionForm9",
    "ConcussionForm10",
    "ConcussionForm11",
  ];
  let totalSymptomsCount = 0;
  let totalSeverityScore = 0;

  allFormKeys.forEach((key) => {
    const formData = JSON.parse(localStorage.getItem(key)) || {};
    Object.values(formData).forEach((value) => {
      if (value > 0) {
        totalSymptomsCount++;
        totalSeverityScore += value;
      }
    });
  });
  return {
    totalSymptoms: totalSymptomsCount,
    severityScore: totalSeverityScore,
  };
};

export const calculateScoreFollowUpForm = (id) => {
  const allFormKeys = [
    `ConcussionFollowUp3-${id}`,
    `ConcussionFollowUp4-${id}`,
    `ConcussionFollowUp5-${id}`,
  ];
  let totalSymptomsCount = 0;
  let totalSeverityScore = 0;

  allFormKeys.forEach((key) => {
    const formData = JSON.parse(localStorage.getItem(key)) || {};
    Object.values(formData).forEach((value) => {
      if (value > 0) {
        totalSymptomsCount++;
        totalSeverityScore += value;
      }
    });
  });
  return {
    totalSymptoms: totalSymptomsCount,
    severityScore: totalSeverityScore,
  };
};

export const updateScore = (newValues) => {
  const relevantKeys = [
    "headache",
    "head_pressure",
    "neck_pain_score",
    "nausea",
    "dizziness",
    "vision_problems",
    "balance_problems",
    "sensitivity_to_light",
    "sensitivity_to_noise",
    "feeling_slowed_down",
    "feeling_in_fog",
    "dont_feel_right",
    "memory_problems",
    "concentration_problems",
    "fatigue",
    "confusion",
    "drowsiness",
    "anxiety",
    "irritability",
    "sadness",
    "nervousness",
    "trouble_falling_asleep",
  ];

  let totalSymptoms = 0;
  let severityScore = 0;

  relevantKeys.forEach((key) => {
    const value = newValues[key];
    if (typeof value === "number" && value > 0) {
      totalSymptoms += 1;
    }

    if (typeof value === "number") {
      severityScore += value;
    }
  });

  return { totalSymptoms, severityScore };
};
